// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-templates-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/templates/blog-templates.js" /* webpackChunkName: "component---src-templates-blog-templates-js" */),
  "component---src-pages-404-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brands-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-contacts-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacypolicy-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-termsandconditions-js": () => import("/Users/andreiagnomelo/Desktop/ProjectCTX/cortex_website/src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */)
}

